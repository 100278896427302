import React, { useEffect, useState,useCallback } from 'react';
import { Box } from '@mui/system';
import { Button, IconButton,Typography, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { updatePassword } from '../../../services/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, useParams } from 'react-router-dom';
import ConnexionLayout from '../../../components/ConnexionLayout/ConnexionLayout';
import TextFieldCustom from '../../../components/TextFieldCustom/TextFieldCustom';
import { useNotifications } from '../../../services/notificationsContext';
import useKeyPress from '../../../services/useKeyPress';


const ResetPassword = (props) => {
  const theme = useTheme();
  const {type,token,mail} = useParams();

  const [showPasswordA,setShowPasswordA] = useState(false)
  const [showPasswordB,setShowPasswordB] = useState(false)

  const handleShowPasswordA = () => {
      setShowPasswordA(showPasswordA ? false : true)
  }
  const handleShowPasswordB = () => {
    setShowPasswordB(showPasswordB ? false : true)
  }

  const [passwordA,setPasswordA] = useState('')
  const [passwordB,setPasswordB] = useState('')
  const [isValidPassword,setIsValidPassword] = useState(false)

  const {pushNotifications} = useNotifications()

  useEffect(()=>{
    const regex =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
    let check = passwordA === passwordB && passwordA.length >= 8  && regex.exec(passwordA) ? true : false 
    setIsValidPassword(check)


    // eslint-disable-next-line
  },[passwordA,passwordB])

  const [validatingPassword,setValidatingPassword] = useState(false)
  const [responsePassword,setResponsePassword] = useState('')


  const validatePassword = useCallback(async () => {
    if(isValidPassword){
      setValidatingPassword(true)
      const response = await updatePassword(mail,token,passwordA)
      pushNotifications(response.message)
      setResponsePassword(response.message)
      setValidatingPassword(false)
      setConfirmation(true)
    }
  },[isValidPassword,mail,passwordA,pushNotifications,token])


  const textePremiereConnexion = {
      title:'Mon espace recrutement',
      text : 'Veuillez définir votre mot de passe pour vous connecter.',
      ctaLabel: 'Définir mon mot de passe',
      page:'premiere-connexion',
      confirmationPhrase:'Votre mot de passe a bien été initialisé. Vous pouvez maintenant vous connecter.',

  }

  const texteMotdePasseOublie = {
      title:'Mot de passe oublié',
      text : 'Veuillez définir votre mot de pase pour vous connecter.',
      ctaLabel : 'Réinitaliser mon mot de passe',
      page : 'mot-de-passe-oublie',
      confirmationPhrase:'Votre mot de passe a bien été réinitialisé. Vous pouvez maintenant vous connecter.',
  }

  const data = type === 'mot-de-passe-oublie' ? texteMotdePasseOublie : textePremiereConnexion


  const [confirmation,setConfirmation] = useState(false)

  const enterPress = useKeyPress('Enter')

  useEffect(() => {
    
    if(enterPress && !validatingPassword){
      validatePassword()
    }
    
  }, [enterPress,validatingPassword,validatePassword])
  

  return (
        <ConnexionLayout {...props}>
          <Box sx={{zIndex:2,width:'800px',maxWidth:'100vw',borderRadius:[0,0,theme.shape.borderRadius],backgroundColor:theme.palette.primary.main}}>
              <Box sx={{paddingX:[3,3,'100px'],paddingY:'40px',backgroundColor:theme.palette.background.default,borderRadius:[0,0,theme.shape.borderRadius]}}>
                  <Box sx={{textAlign:'center',marginBottom:'20px'}}>
                    {!confirmation && <>
                    <Typography variant="h1" sx={{marginBottom:'10px'}}>
                      {data.title}
                    </Typography>
                    <Typography>
                    {data.text}
                    </Typography>
                    <Box sx={{width:'100%',marginY:2}}>

                  <Box sx={{marginY:1}}>
                    <TextFieldCustom fullWidth label="Mot de passe" 
                      type={showPasswordA ? "text" : "password"}
                      onChange={(e)=>{setPasswordA(e.target.value)}} 
                      value={passwordA}
                      endIcon={<IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPasswordA}
                      >
                        {!showPasswordA ? <Visibility /> : <VisibilityOff />}
                      </IconButton>}
                      />
                    </Box>
                    <Box sx={{marginY:1}}>
                      <TextFieldCustom fullWidth label="Confirmer mon mot de passe" 
                      type={showPasswordB ? "text" : "password"}
                      value={passwordB}
                      onChange={(e)=>{setPasswordB(e.target.value)}}
                      endIcon={<IconButton
                        aria-label="toggle password visibility"
                        onClick={(e)=>{handleShowPasswordB()}}
                      >
                        {!showPasswordB ? <Visibility /> : <VisibilityOff />}
                      </IconButton>} />

                      {(passwordA !== passwordB && passwordB.length > 3)  &&
                        <Box sx={{marginTop:2,textAlign:'left'}}>
                        <Typography variant="error">Attention, les deux mots de passe doivent être identiques.</Typography>
                        </Box>
                      }
                    </Box>

                    </Box>
                    <Typography sx={{fontSize:'14px'}}>
                      Votre mot de passe doit contenir au minimum 8 caractères dont une lettre majuscule et un chiffre obligatoires.
                    </Typography>


                    <LoadingButton sx={{marginTop:'20px',marginBottom:'20px'}} 
                    variant='contained'
                    disabled={!isValidPassword}
                    loading={validatingPassword}
                    onClick={()=>{validatePassword()}}>
                    {data.ctaLabel}</LoadingButton>

                    {responsePassword &&
                     <Typography>
                        {responsePassword}
                    </Typography>}
                    </> }
                    {confirmation &&
                    <>
                    <Typography variant="h1" sx={{marginBottom:2}}>
                      {data.title}
                    </Typography>
                    {!responsePassword &&
                      <Typography sx={{marginBottom:2}}>
                        {data.confirmationPhrase}
                      </Typography>
                    }

                    {responsePassword &&
                    <Box sx={{marginBottom:2}}>
                      <Typography>{responsePassword}</Typography>
                    </Box>
                    }
                    
                    <Link to={"/?email="+mail} style={{textDecoration:'none',marginTop:2}}>
                      <Button variant='contained'>Me connecter</Button>
                    </Link>



                    </>
                    }

                  </Box>
              </Box>
          </Box>
        </ConnexionLayout>
        )
};

export default ResetPassword;



